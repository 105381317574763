.logo {
  font-size: 25px;
  margin-top: 10px;
  font-weight: 450;
  color: black;
  font-weight: 600;
  font-family: "Poppins", serif !important;
}
.navbar-brand {
  text-decoration: none;
  margin-right: 30px;
  margin-left: 10px;
}
@media screen and (max-width: 700px) {
  .navbar-brand {
    margin: 0;
  }
}
